























import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import StyledSearchbar from '@/modules/common/components/styled-searchbar.vue';
import { Checkbox } from 'element-ui';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';

@Component({
    components: { CustomSelect, StyledSearchbar, 'el-checkbox': Checkbox },
})
export default class PickUpCityFilter extends Vue {
    @Inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @Inject(CarsServiceS) private carsService!: CarsService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;

    private query: string | null = null;
    private check: boolean = false;

    get items(): Item[] {
        const {
            locations, countries, clusters,
        } = this.carsFiltersService.settings;

        const { clusterMode } = this.carsFiltersService.settings.features;

        const {
            country, pickUpCityCode, cluster, isAvgPrice,
        } = this.carsService.storeState.settings;

        if (!country || !countries || !locations) {
            return [];
        }

        let filteredLocations = this.carsFiltersService.filterPickUpCitiesByCountry(country);

        if (cluster && country && clusters && clusterMode) {
            const locationsIds = clusters[country][cluster];
            filteredLocations = filteredLocations.filter(location => locationsIds.includes(location.locationId));
        }

        if (!pickUpCityCode || !filteredLocations.find(location => location.locationId === pickUpCityCode)) {
            this.currentValue = filteredLocations && filteredLocations.length ? filteredLocations[0].locationId : '';
        }

        if (this.query) {
            const searchPattern = new RegExp(this.query.toLowerCase());
            filteredLocations = filteredLocations.filter(location => searchPattern.test(location.locationName.toLowerCase()));
        }

        const result = filteredLocations.map(location => ({
            name: location.locationName,
            value: location.locationId,
            disabled: isAvgPrice && clusterMode,
        }));

        if (!result.length) {
            result.push({
                name: 'No results',
                value: 'No results',
                disabled: true,
            });
        }

        return result;
    }

    get currentValue() {
        const { locations } = this.carsFiltersService.settings;
        const { pickUpCityCode } = this.carsService.storeState.settings;

        if (!locations || !pickUpCityCode || this.currentIsAvgPriceValue) {
            return null;
        }

        const currentLocation = locations.find(location => location.locationId === pickUpCityCode);

        if (!currentLocation) {
            return locations[0].locationId;
        }

        return currentLocation.locationId;
    }

    set currentValue(value) {
        if (value) {
            this.carsSharedService.savePickUpCityAndCountry(value, this.$route.path);
        }
    }

    handleChange(query: string) {
        this.query = query;
    }

    handleCheck(val: boolean) {
        this.carsService.storeState.settings.isAvgPrice = val;
        this.check = val;
    }

    get currentIsAvgPriceValue() {
        return this.carsService.isAvgPrice;
    }

    set currentIsAvgPriceValue(value) {
        this.carsService.isAvgPrice = value;
    }

    get clusterMode() {
        return this.carsFiltersService.settings.features.clusterMode;
    }

    get cluster() {
        return !!this.carsService.storeState.settings.cluster;
    }
}
