


































import { Component, Mixins } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CarRatesPageOtelLogs from '@/modules/cars/modules/open-telemetry/mixins/car-rates-page-otel-logs.mixin';
import CarColorPicker from '@/modules/cars/components/car-color-picker.vue';
import CarCalendar from '@/modules/cars/components/car-calendar.vue';
import CarActions from '@/modules/cars/components/car-actions.vue';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import CarHeader from '@/modules/cars/components/car-header.vue';
import CarRatesTooltip from '@/modules/cars/components/car-rates-tooltip.vue';
import CarTableV2 from '@/modules/cars/components/car-table-v2.vue';
import CarTogglePriceTypes from '@/modules/cars/components/car-toggle-price-types.vue';
import ToggleDiff from '@/modules/common/modules/rates/components/toggle-diff.vue';
import CarTogglePrices from '@/modules/cars/components/car-toggle-prices.vue';
import CarsSwitcher from '@/modules/cars/components/cars-switcher.vue';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';

@Component({
    components: {
        CarColorPicker,
        CarCalendar,
        CarActions,
        PageWrapper,
        CarHeader,
        CarRatesTooltip,
        CarTableV2,
        ToggleDiff,
        CarTogglePrices,
        CarsSwitcher,
        CarTogglePriceTypes,
    },
})
export default class RatesCalendarPage extends Mixins(CarRatesPageOtelLogs) {
    @Inject(CarsServiceS) readonly carsService!: CarsService;
}
