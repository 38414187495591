import { Component, Mixins, Watch } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import BaseOtelMixin from '@/modules/cars/modules/open-telemetry/mixins/common/base-otel.mixin';
import { LOGTYPE } from '@/modules/open-telemetry/constants';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';

/**
 * Mixin for rates pages.
 * Open OTEL span on before component mount and end it, on finishing document loading
 * (it doesn't take in count time for rerender component after document update).
 */
@Component
export default class CarRatesPageOtelLogs extends Mixins(BaseOtelMixin) {
    @Inject(CarsSharedServiceS) carsSharedService!: CarsSharedService;
    @Inject(DocumentFiltersServiceS) documentFiltersService!: DocumentFiltersService;

    static readonly spanPrefix = LOGTYPE.LOADING;

    beforeMount() {
        this.startSpan(CarRatesPageOtelLogs.spanPrefix);
    }

    mounted() {
        this.addEvent('mounted', CarRatesPageOtelLogs.spanPrefix);
        this.endSpanWhenLoadingFinished();
    }

    @Watch('carsSharedService.carsStoreState.settings.isVans')
    isVansChanged() {
        const isSpanExists = this.isSpanExists(CarRatesPageOtelLogs.spanPrefix);
        if (!isSpanExists) {
            this.postfix = `isVans=${this.carsSharedService.carsStoreState.settings.isVans}`;
            this.startSpan(CarRatesPageOtelLogs.spanPrefix);
            this.addEvent('Vans switched', CarRatesPageOtelLogs.spanPrefix);
            this.endSpanWhenLoadingFinished();
        }
    }

    endSpanWhenLoadingFinished() {
        this.carsSharedService.carsStoreState.loading.whenLoadingFinished()
            .then(_ => {
                this.addEvent('gotRespnose', CarRatesPageOtelLogs.spanPrefix);
                this.endSpan(CarRatesPageOtelLogs.spanPrefix, { sendLogs: true, payload: this.preparePayload(this.carsSharedService.carsStoreState.settings) });
            });
    }
}
