








































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import Day from '@/modules/common/types/day.type';
import CalendarItemWrapper from '@/modules/common/components/ui-kit/calendar-item-wrapper.vue';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import CalendarEventsContainer from '@/modules/events/components/calendar-events-container.vue';
import Occupancy from '@/modules/common/components/ui-kit/occupancy.vue';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import CarsFiltersService, { CarsFiltersServiceS } from '../cars-filters.service';
import CarRatesDayScanBtn from './car-rates-day-scan-btn.vue';

@Component({
    components: {
        CalendarItemWrapper,
        CalendarEventsContainer,
        Occupancy,
        CarRatesDayScanBtn,
    },
})
export default class CarCalendarItemNoAvailability extends Vue {
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;
    @Inject(CarsServiceS) private carsService!: CarsService;
    @Inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;

    @Prop({
        required: true,
        type: Number,
    })
    day!: Day;

    get occupancy(): number | null {
        return Number(this.carsService.occupancy(this.day));
    }

    get isAvailability() {
        return this.carsSharedService.isAvailability;
    }
}
