










import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import Day from '@/modules/common/types/day.type';
import ScanBtnSmall from '@/modules/common/components/ui-kit/scan-btn-small.vue';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import NotifyService, { NotifyServiceS } from '@/modules/common/services/notify.service';
import CarsFiltersService, { CarsFiltersServiceS } from '../cars-filters.service';
import CarsService, { CarsServiceS } from '../cars.service';
import CarsScanApiService, { CarsScanApiServiceS } from '../cars-scan-api.service';
import { BRAND } from '../constants/data-source-mode.constant';
import CarsSharedService, { CarsSharedServiceS } from '../cars-shared.service';

@Component({
    components: {
        ScanBtnSmall,
    },
})
export default class CarRatesDayScanBtn extends Vue {
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(CarsServiceS) private carsService!: CarsService;
    @Inject(CarsFiltersServiceS) carsFilterService!: CarsFiltersService;
    @Inject(CarsScanApiServiceS) carsScanApiService!: CarsScanApiService;
    @Inject(NotifyServiceS) private notifyService!: NotifyService;
    @Inject(CarsSharedServiceS) protected carsSharedService!: CarsSharedService;

    @Prop({
        type: Number,
        required: true,
    })
    private day!: Day;

    isEnabled: boolean = true;

    get isScanning() {
        return Boolean(this.carsFilterService.storeState.document?.ondemandStatus?.[this.day]);
    }

    get disabled() {
        return this.documentFiltersService.isPreviousDay(this.day as Day)
            || this.documentFiltersService.isPreviousMonth || !!this.carsSharedService.carsStoreState.settings.cluster;
    }

    async handleClick(event: Event) {
        if (this.isScanning || this.disabled) {
            return;
        }
        event?.stopPropagation();
        event.preventDefault();

        const { lor, pos, dataSource, pickUpCityCode, isVans, competitors } = this.carsService.storeState.settings;
        const unselectedCompetitors = this.carsSharedService.getUnselectedCompetitors();
        const competitorsFilter = competitors?.length ? competitors.filter(item => !unselectedCompetitors.includes(item)) : [];
        let providers: string[] = [];
        if (dataSource?.toLocaleLowerCase() === BRAND.toLocaleLowerCase()) {
            providers = competitorsFilter?.length ? competitorsFilter : this.getProvidersFromCompsetSetting(pickUpCityCode, dataSource);
        }

        const { year, month } = this.documentFiltersService;
        if (lor && pos && dataSource && pickUpCityCode && year) {
            const chain = this.carsSharedService.currentChain;
            const res: any = await this.carsScanApiService.runOndmandScan(
                {
                    start_date: `${year}-${month + 1}-${this.day}`,
                    lor,
                    pos,
                    data_source: dataSource,
                    location_code: pickUpCityCode,
                    providers,
                    is_van: isVans,
                    chain_id: this.carsSharedService.currentChain?.chainId || '',
                },
            );

            if (res?.data?.status === 'error') {
                this.notifyService.error(res?.data?.msg);
                return;
            }
            this.carsService.storeState.loadAfterOndemand = true;
            await this.carsService.loadData(false);
            this.carsService.storeState.loadAfterOndemand = false;
        }
    }

    getProvidersFromCompsetSetting(pickUpCityCode: string | null, dataSource: string | null) {
        if (!pickUpCityCode || !dataSource) {
            return [];
        }
        const country = this.carsFilterService.getCountryByPickUpLocationId(pickUpCityCode);
        if (dataSource && country && this.carsFilterService.allowedVendorsPerCountry[country]) {
            return this.carsFilterService.allowedVendorsPerCountry[country][dataSource] || [];
        }
        return [];
    }

    get formatScanDate() {
        const dateScan = this.carsService.calculateShopDate(this.day);

        if (!dateScan) {
            return '--/--/--';
        }

        const currentDate = new Date();

        const date = dateScan.getDate();
        const month = dateScan.getMonth();
        const year = dateScan.getFullYear();

        const todayDate = currentDate.getDate();
        const todayMonth = currentDate.getMonth();
        const todayYear = currentDate.getFullYear();

        const lastDateSum = date + month + year;
        const todaySum = todayDate + todayMonth + todayYear;

        let todayOrYesterday = '';
        if (lastDateSum - todaySum === -1) {
            todayOrYesterday = 'yesterday';
        } else if (lastDateSum - todaySum === 0) {
            todayOrYesterday = 'today';
        }

        if (todayOrYesterday) {
            return `${todayOrYesterday}, ${new Intl.DateTimeFormat('default', {
                hour12: true,
                hour: 'numeric',
                minute: 'numeric',
            }).format(dateScan)}`;
        }

        return `${date}/${month + 1}/${year}`;
    }
}
