


























import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import Item from '@/modules/common/interfaces/item.interface';
import { $enum } from 'ts-enum-util';
import CarsService, { CarsServiceS } from '../cars.service';
import CAR_PRICE_TYPE from '../constants/car-price-type.constant';

@Component
export default class CarTogglePriceTypes extends Vue {
    @Inject(CarsServiceS) private carsService!: CarsService;

    get options() {
        return $enum(CAR_PRICE_TYPE).map((value): Item => ({
            value,
            name: this.$t(`cars.priceType.${value}`) as string,
        }));
    }

    set priceType(value: CAR_PRICE_TYPE) {
        this.carsService.storeState.settings.priceType = value;
    }

    get priceType(): CAR_PRICE_TYPE {
        return this.carsService.storeState.settings.priceType;
    }
}
